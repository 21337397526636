import React, { useState, useEffect } from 'react';
import { Button, TextField, List, ListItem, ListItemText, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { compareDate, FormatDate } from '../../utils/DateFormats';
import { toast } from 'react-toastify';
import db from "../../utils/Utility";
import constants from '../../Constants';
import {  Select, MenuItem, FormControl, InputLabel } from '@mui/material'; // Ensure these are correctly imported

import axios from 'axios';

// Function to interact with IndexedDB
const dbName = 'roznamcha';
const storeName = 'Currency';

const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
    };

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

const addCurrency = async (currency) => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.add({ currency });

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

const getCurrencies = async () => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.getAll();

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

const deleteCurrency = async (id) => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.delete(id);

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

const CurrencyActivate = () => {
  const [currencies, setCurrencies] = useState([]);
  const [newCurrency, setNewCurrency] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const navigate = useNavigate();
  const [isApp, setIsApp] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [clientName, setClientName] = useState('');

  function isInStandaloneMode() {
    console.log(window.navigator.standalone, ' navigator')
    // Check if the browser is running in standalone mode on iOS
    if (window.navigator.standalone) {
      console.log(isApp+'  IOS')
        setIsApp(true);
        return true;
    }
  
    // Check if the browser is running in standalone mode on Android
    if (window.matchMedia('(display-mode: standalone)').matches) {
      console.log(isApp+'  Android')
        setIsApp(true);
        return true;
    }
  
    // If neither condition is met, return false
    setIsApp(false);
    console.log(isApp+'  Nothing Met ')
    return false;
  }
  useEffect(() => {
    isInStandaloneMode();
    if (isApp) {
        // Hide install button if the app is already installed
        return;
    }

    window.addEventListener('beforeinstallprompt', (event) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        console.log(event)
        event.preventDefault();
        // Stash the event so it can be triggered later
        setDeferredPrompt(event);
    });
    console.log(deferredPrompt, 'defferedPrompt')

    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            // Show the prompt
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                // Reset the deferredPrompt
                setDeferredPrompt(null);
            });
        }
    };


  useEffect(() => {
    const checkCurrencyStore = async () => {
        fetchCurrencies()
    //   const currencies = await getCurrencies();
      const profile = await db.getAll('Profile');
      console.log(profile)
      if (profile.length > 0) {
        navigate('/dashboard');
      } else {
        setCurrencies([]);
      }
    };

    checkCurrencyStore();
  }, [navigate]);

  const handleAddCurrency = async (event) => {
    event.preventDefault();
    if (newCurrency.trim()) {
      await addCurrency(newCurrency);
      setNewCurrency('');
      fetchCurrencies();
    }
  };

  const handleDeleteCurrency = async (id) => {
    await deleteCurrency(id);
    fetchCurrencies();
  };

  const fetchCurrencies = async () => {
    const currencies = await getCurrencies();
    setCurrencies(currencies);
  };

  const handleSkip = async() => {
    const addMonths = (date, months) => {
      const d = new Date(date);
      console.log(date, months)
      d.setMonth(d.getMonth() + months);
      return d;
    };
    const d = Date.now();
    const getAlterDate =addMonths(d, 0) 
    const getDate = FormatDate(getAlterDate)
    console.log(getDate)
    const profileValues = {
        mobile: mobile,
        password: password,
        activationDate: getDate,
        netCheckIn: '',
        netCheckOut: '',
        months: 0,
      }
    console.log(profileValues)
    try {
        const payload = profileValues;
        payload.currencies = currencies
        payload.clientName = clientName
        axios.post('https://roznamchaback.wasily.net/register', payload)
      .then(async response => {
        console.log(response)
        if(response.status === 200) {
        await db.set('Profile', profileValues);
        // setData(response.data);
        navigate('/dashboard');

        }
      })
      .catch(err => {
        console.log(err)
        // setError(err.message);
      });
    // axiosInstance.post('https://smartschool.host/WebSr', {WebSrn}).then(response => {
    //   console.log(response.data)
    // }).catch( error => {
    //   console.log('app error', error);
    // })
        // navigate('/dashboard');
    } catch (error) {
            console.log(error)
    }
  };

  const recover = () =>{
    navigate('/recover');
  }
  return (
    <Container className='currencyActivate'>
      {
      isApp === false ?
          <Box display="flex" alignItems="center" justifyContent="center">
              <button onClick={handleInstallClick}>Install Mobile Application</button>
          </Box>
      :
      <>
      <Typography variant="h4" gutterBottom>
        Create/Activate Account
      </Typography>
      <form onSubmit={handleAddCurrency}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
        <TextField
          label="Mobile No."
          variant="outlined"
          fullWidth
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FormControl fullWidth variant="outlined" style={{ marginTop: 16 }}>
        {/* <InputLabel>Month</InputLabel> */}
        {/* <Select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
          label="Month"
        >
          {[...Array(12).keys()].map((i) => (
            <MenuItem key={i + 1} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Select> */}
      </FormControl>
      <br/>
      <TextField
          label="Currency"
          variant="outlined"
          fullWidth
          value={newCurrency}
          onChange={(e) => setNewCurrency(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" style={{ marginTop: 16 }}>
          Add Currency
        </Button>
      </form>
      <List>
        {currencies.map((currency) => (
          <ListItem key={currency.id}>
            <ListItemText primary={currency.currency} />
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleDeleteCurrency(currency.id)}
            >
              Delete
            </Button>
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleSkip}
        style={{ marginTop: 16 }}
      >
        Submit
      </Button>
      <br/>
      <Button onClick={recover}>
        Recover My Old Roznamcha
      </Button>
      </>
    }
    </Container>
  );
};

export default CurrencyActivate;
